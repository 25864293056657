import { HINTS, SOFTWARE_URLS } from "../../components/goto/index.content";

class InitController {
  constructor(updateState) {
    this.urlParams = new URL(window.location.href).searchParams;
    this.url = this.urlParams.get("url") || "";
    this.type = this.urlParams.toString().includes(".pdf") ? "pdf" : "url";
    this.userLang = navigator.language || navigator.userLanguage;
    this.success = false;
    this.utms = "";
    this.updateState = updateState;
  }

  init() {
    this.checkUrl();
    this.checkUserLang();
    this.getUtms();
    this.checkType();
    this.updateAllState(this.updateState);
  }

  checkUrl() {
    SOFTWARE_URLS.forEach(({ name, shortName, link }) => {
      const key = this.url.toString().toUpperCase();
      if (name === key || shortName.toUpperCase() === key) {
        this.url = link;
        this.success = true;
      }
    });
  }

  checkUserLang() {
    this.userLang = this.userLang.toLowerCase().substring(0, 2);
    this.userLang = Object.keys(HINTS).includes(this.userLang)
      ? this.userLang
      : "en";
  }

  getUtms() {
    const params = [];
    for (const param of this.urlParams.entries()) {
      if (param[0].startsWith("utm_")) {
        params.push(`${param[0]}=${encodeURIComponent(param[1])}`);
      }
    }
    params.forEach((p) => (this.utms = this.utms + p + "&"));
  }

  checkType() {
    if (this.type !== "pdf" && this.success) {
      this.type = "store";
    }
  }

  updateAllState(updateState) {
    updateState("url", this.url);
    this.userLang !== "en" && updateState("userLang", this.userLang);
    this.success && updateState("success", this.success);
    updateState("utms", this.utms);
    updateState("type", this.type);
    updateState("isSubmit", true);
  }
}

export default InitController;
