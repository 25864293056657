import { ALLOWED_URLS } from "../../components/goto/index.content";
import track from "../../tracking/analytics";
import { trackGa } from "../../tracking/googleAnalytics";
import { log } from "../../helpers";

class SubmitController {
  constructor(url, success, utms) {
    this.urlParams = new URL(window.location.href).searchParams;
    this.ck = this.urlParams.get("ck");
    this.ct = this.urlParams.get("ct");
    this.referrer = document.referrer || "null";
    this.url = url;
    this.success = success;
    this.utms = utms;
    this.err = "";
    this.errorPage = "/error/404.html";
  }

  submit() {
    this.checkAllowedUrls();
    const goto = this.url + (this.utms ? "?" + this.utms.slice(0, -1) : "");
    this.sendAnalytics(goto).then((res) => {
      if (res === "ok") {
        this.success
          ? this.successRedirect(goto)
          : this.errorRedirect(this.err);
      }
    });
  }

  checkType() {
    if (this.type !== "pdf" && this.success) {
      this.type = "store";
    }
  }

  sendAnalytics(goto) {
    return new Promise((res) => {
      if (window.gtag) {
        const decodedUrl = this.err
          ? this.errorPage
          : decodeURIComponent(this.url);
        const action = this.err ? "goto_link_click_error" : "goto_link_click";
        track("Redirect", decodedUrl, this.referrer);
        trackGa(action, {
          category: "LANDING|Redirect",
          link_url: goto,
          page_url: this.referrer,
          transport_type: "beacon",
        });
        res("ok");
      } else {
        window.setTimeout(() => {
          res(this.sendAnalytics());
        }, 200);
      }
    });
  }

  errorRedirect(err) {
    log(false, err);
    window.location.href = this.errorPage;
  }

  checkAllowedUrls() {
    if (!this.success) {
      try {
        const domain = new URL(this.url).origin.split("//")[1];
        this.success = ALLOWED_URLS.some((it) => {
          if (it.startsWith("*.")) {
            return domain.endsWith(it.substring(2));
          } else {
            throw Error("wrong domain");
          }
        });
      } catch (err) {
        this.err = err;
      }
    }
  }

  recordCookie(ck, ct = 30) {
    const date = new Date(Date.now() + ct * 24 * 60 * 60 * 1000);
    const cks = JSON.parse(ck);
    Object.keys(cks).forEach((name) => {
      document.cookie =
        `${name}=${cks[name]}; path=/; expires=` + date.toUTCString();
    });
  }

  successRedirect(goto) {
    this.ck && this.recordCookie(this.ck, this.ct || 30);
    window.location.href = goto;
  }
}

export default SubmitController;
