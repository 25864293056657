import React from "react";
import PropTypes from "prop-types";
import GIFLoader from "../../../assets/images/gif/loader.gif";
import "./index.scss";

const Message = ({ text }) => (
  <section className="PgGT-Message">
    <div className="PgGT-Message__card">
      <img src={GIFLoader} alt="..." />
      <p className="PgGT-Message__text">{text}</p>
    </div>
  </section>
);

Message.propType = {
  text: PropTypes.string,
};

export default Message;
