import React, { useEffect, useState } from "react";
import SvgDarkLogo from "../assets/images/vector/components/common/logo_amzscout_black.svg";
import OgImg from "../assets/images/raster/components/common/mainscreen.png";
import { HINTS_DEFAULT, HINTS } from "../components/goto/index.content";
import Seo from "../components/Seo";
import Layout from "../layouts/LayoutEmpty";
import Message from "../components/goto/Message";
import PdfMessage from "../components/goto/PdfMessage";
import InitController from "../controllers/goto/initController";
import SubmitController from "../controllers/goto/SubmitController";
import "../components/goto/index.scss";

const GoTo = () => {
  const [getState, setState] = useState({
    url: "",
    type: "",
    utms: "",
    success: false,
    userLang: "en",
    isSubmit: false,
  });

  const updateState = (key, value) => {
    setState((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  useEffect(() => {
    if (!getState.isSubmit) {
      new InitController(updateState).init();
    } else {
      new SubmitController(
        getState.url,
        getState.success,
        getState.utms
      ).submit();
    }
  }, [getState.isSubmit]);

  const text = getState.type
    ? HINTS[getState.userLang][getState.type]
    : HINTS_DEFAULT;
  return (
    <Layout className="PgGT" hideCookiePanel disableHelpBlock>
      <Seo
        title="AMZScout"
        description=""
        page=""
        manifest="browserconfig-index.xml"
        ogImg={OgImg}
      />
      {getState.type !== "pdf" && (
        <img src={SvgDarkLogo} alt="..." className="PgGT__logo" />
      )}
      {getState.type === "pdf" ? <PdfMessage /> : <Message text={text} />}
    </Layout>
  );
};

export default GoTo;
