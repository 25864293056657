import React from "react";
import SvgDarkLogo from "../../../assets/images/vector/components/common/logo_amzscout_black.svg";
import GIFLoader from "../../../assets/images/gif/loader.gif";
import "./index.scss";

const PdfMessage = () => (
  <section className="PgGT-PdfMessage">
    <div className="PgGT-PdfMessage__card">
      <img src={SvgDarkLogo} alt="logo" className="PgGT-PdfMessage__logo" />
      <img src={GIFLoader} alt="..." className="PgGT-PdfMessage__loader" />
    </div>
  </section>
);

export default PdfMessage;
